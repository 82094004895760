/* eslint-disable react/prop-types */
import React, { Fragment, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Placeholder } from 'semantic-ui-react'
import StatusProgression from './StatusProgression'
import ReminderSection from './ReminderSection'
import PayButtons from './PayButtons'
import CompleteButton from './CompleteButton'
import { SingleProjectContext } from '../../../../Context'
import { LOAD_MANAGE_ADVISOR_CARD } from '../../../../graphql/job'

const ManageAdvisorCard = ({ advisor, job = {} }) => {
    const { project } = useContext(SingleProjectContext)

    const { data, loading } = useQuery(LOAD_MANAGE_ADVISOR_CARD, {
        skip: !job.id,
        variables: { id: job.id },
    })

    if (loading)
        return (
            <Placeholder>
                <Placeholder.Paragraph>
                    <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>
        )
    if (!data) return <div />

    return (
        <Fragment>
            <StatusProgression project={project} job={data.job} />
            <ReminderSection
                advisor={advisor}
                project={project}
                job={data.job}
            />
            <Fragment>
                {job.overall_status === 'Completed' && (
                    <PayButtons advisor={advisor} job={data.job} />
                )}
                {!['Completed', 'Paid'].includes(job.overall_status) && (
                    <CompleteButton
                        advisor={advisor}
                        project={project}
                        job={data.job}
                    />
                )}
            </Fragment>
        </Fragment>
    )
}

export default ManageAdvisorCard
