/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    List,
    Input,
    Label,
    Radio,
    Button,
    Icon,
    Checkbox,
} from 'semantic-ui-react'
import {
    ContentState,
    EditorState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import AdvisorTags from '../../Advisors/AdvisorTags'
import { getHTMLFromEditorState } from '../../../utils/helpers'
import { getAfterPaymentMessageMarkup } from '../../Emails'
import { AppContext } from '../../../Context'

const PaymentInfo = ({ advisor, project, job, onBack, onNext }) => {
    const { user } = useContext(AppContext)

    const [editorState, setEditorState] = useState(null)
    const [paymentAmount, setPaymentAmount] = useState(job.offerRate || 0)
    const [paymentDescription, setPaymentDescription] = useState('')
    const [hasMessage, setHasMessage] = useState(false)
    const [message, setMessage] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(
        advisor.expert.stripe_account ? 'Stripe' : null
    )

    useEffect(() => {
        if (hasMessage && !message) {
            const markup = getAfterPaymentMessageMarkup(
                advisor.expert.salutation,
                advisor.firstName,
                advisor.lastName,
                project.title,
                paymentAmount,
                user.fullName || user.email
            )
            const blocksFromHTML = convertFromHTML(markup)
            const editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                )
            )
            setEditorState(editorState)
            setMessage(
                getHTMLFromEditorState(
                    JSON.stringify(
                        convertToRaw(editorState.getCurrentContent())
                    )
                )
            )
        }
    }, [hasMessage, message, advisor, project, user, paymentAmount])

    // eslint-disable-next-line no-case-declarations
    const renderPaymentMethodText = (checked) => (
        <i
            style={{
                marginLeft: '1rem',
                color: 'red',
                display: checked ? 'inline' : 'none',
            }}
        >
            I have confirmed this payment method with the expert
        </i>
    )

    return (
        <>
            <Modal.Body>
                {job.meetingReport &&
                    job.meetingReport.status === 'Complete' && (
                        <>
                            <label
                                style={{
                                    fontSize: '13px',
                                    color: 'black',
                                }}
                            >
                                Meeting Participants
                            </label>
                            <List size="tiny" bulleted>
                                {job.meetingReport.participants.map(
                                    (participant, index) => {
                                        return (
                                            <List.Item key={index}>
                                                {`
                                            ${participant.name} ${
                                                participant.email
                                                    ? `(${participant.email})`
                                                    : ''
                                            }: 
                                            ${moment(
                                                participant.joinedAt
                                            ).format('LT')} - ${moment(
                                                participant.leftAt
                                            ).format('LT')}
                                            (${participant.numMinutes} minutes)
                                        `}
                                            </List.Item>
                                        )
                                    }
                                )}
                            </List>
                        </>
                    )}
                <Form>
                    {project.team.name !== 'Decibio' && (
                        <Form.Field>
                            <label>
                                {`Compensation offered at invitation: $${job.offerRate} per hour`}
                            </label>
                        </Form.Field>
                    )}
                    <Form.Field>
                        <label>Payment amount</label>
                        <Input
                            labelPosition="right"
                            type="number"
                            min="0"
                            placeholder="Amount"
                            value={isNaN(paymentAmount) ? '' : paymentAmount}
                            onChange={(e, { value }) =>
                                setPaymentAmount(parseFloat(value))
                            }
                        >
                            <Label basic>$</Label>
                            <input />
                            <Label>USD</Label>
                        </Input>
                    </Form.Field>
                    {!advisor.expert.stripe_account && (
                        <>
                            <Form.Field>
                                <label>Payment method</label>
                                <Radio
                                    label="Check"
                                    value="Check"
                                    checked={paymentMethod === 'Check'}
                                    onChange={(e, { value }) =>
                                        setPaymentMethod(value)
                                    }
                                />
                                {renderPaymentMethodText(
                                    paymentMethod === 'Check'
                                )}
                            </Form.Field>
                            <Form.Field>
                                <Radio
                                    label="PayPal"
                                    value="PayPal"
                                    checked={paymentMethod === 'PayPal'}
                                    onChange={(e, { value }) =>
                                        setPaymentMethod(value)
                                    }
                                />
                                {renderPaymentMethodText(
                                    paymentMethod === 'PayPal'
                                )}
                            </Form.Field>
                        </>
                    )}
                    <Form.TextArea
                        label="Payment description"
                        className="payment-description"
                        row="2"
                        onChange={(e, { value }) =>
                            setPaymentDescription(value)
                        }
                        defaultValue="Enter Payment Description in the following format - (Total Amount,Minutes * Rate ,Other Details,Analyst Name)"
                    />
                    <Form.Field>
                        <label>Tags</label>
                    </Form.Field>
                    <div>
                        <AdvisorTags advisor={advisor} />
                    </div>
                </Form>
                <br />
                <Form>
                    <Form.Field>
                        <label>Message</label>
                        <Checkbox
                            label={`Send a custom message to ${
                                advisor.fullName || advisor.email
                            }`}
                            checked={hasMessage}
                            onChange={(e, { checked }) => {
                                setHasMessage(checked)
                                setMessage(null)
                            }}
                        />
                    </Form.Field>
                    {hasMessage && (
                        <Form.Field>
                            <label>
                                Write a custom message below. This will be sent
                                as a plain text email with no Dexter logo or
                                text formatting and with the subject line, "Re:
                                Your Dexter interview on {project.title}". The
                                expert will still receive an automated
                                notification from Dexter.
                            </label>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={(editorState) => {
                                    setEditorState(editorState)
                                    setMessage(
                                        getHTMLFromEditorState(
                                            JSON.stringify(
                                                convertToRaw(
                                                    editorState.getCurrentContent()
                                                )
                                            )
                                        )
                                    )
                                }}
                            />
                        </Form.Field>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button icon basic onClick={onBack}>
                    <Icon name="angle left" />
                </Button>
                <Button
                    onClick={() =>
                        onNext({
                            paymentAmount,
                            paymentMethod,
                            paymentDescription,
                            message,
                            hasMessage,
                        })
                    }
                >
                    Continue
                </Button>
            </Modal.Footer>
        </>
    )
}

export default PaymentInfo
