/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import { Button } from 'semantic-ui-react'
import moment from 'moment'
import PaymentModal from '../../../Modals/Payment/PaymentModal'

const PayButtons = ({ advisor, job }) => {
    const [showPaymentModal, setShowPaymentModal] = useState(false)

    const renderPaidText = () => (
        <span style={{ float: 'right' }}>
            {`Paid $ ${job.paymentAmount} on ${moment(job.paidAt).format(
                'MM/DD/YY'
            )} via ${job.paymentMethod}`}
        </span>
    )

    const renderPayButton = () => (
        <Button
            size="mini"
            floated="right"
            color="red"
            onClick={() => {
                setShowPaymentModal(true)
            }}
        >
            {advisor.expert.stripe_account ? 'Pay' : 'Record external payment'}
        </Button>
    )

    return (
        <Fragment>
            <PaymentModal
                onShow={showPaymentModal}
                onHide={() => setShowPaymentModal(false)}
                advisor={advisor}
                job={job}
            />
            {job.overall_status === 'Paid'
                ? renderPaidText()
                : renderPayButton()}
        </Fragment>
    )
}

export default PayButtons
