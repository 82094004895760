import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Dashboard from './Dashboard'
import ManageTeam from './Team'
import TeamManage from './Team/TeamManage'
import ManageProject from './Project'
import ManageJob from './Job'
import ManageOrganization from './Organization'
import ManageExpert from './Expert'

const AdminRoutes = () => {
    return (
        <Switch>
            <Route
                exact
                path="/admin"
                render={() => <Redirect to="/admin/teams" />}
            />
            <Route exact path="/admin/dashboard" component={Dashboard} />
            <Route exact path="/admin/teams" component={ManageTeam} />
            <Route exact path="/admin/teams/:id/:page" component={TeamManage} />
            <Route exact path="/admin/projects" component={ManageProject} />
            <Route exact path="/admin/jobs" component={ManageJob} />
            <Route
                exact
                path="/admin/organizations"
                component={ManageOrganization}
            />
            <Route exact path="/admin/experts" component={ManageExpert} />
            <Route render={() => <Redirect to="/not-found-page" />} />
        </Switch>
    )
}

export default AdminRoutes
