import React, { useState, useEffect } from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import auth0Client from './components/Auth/Auth'
import Callback from './components/Auth/Callback'
import SecureRoute from './components/SecureRoute'
import AppHeader from './components/AppHeader'
import SingleProject from './views/SingleProject'
import Project from './views/Projects'
import NewProject from './views/NewProject'
import Payment from './views/Payments'
import Advisors from './views/Advisors'
import Tutorial from './views/Tutorial'
import Admin from './views/Admin'
import ExtractionReview from './views/ExtractionReview'
import FirstTimeSignupPage from './views/FirstTimeSignupPage'
import FAQ from './views/FAQ'
import Job from './views/Job'
import SignIn from './components/Auth/SignIn'
import NoMatch from './components/NotFoundPage'
import { LOAD_ME_QUERY } from './graphql/user'
import { AppContext } from './Context'
import { isSuperAdmin, isTeamAdmin, isTeamMember } from './utils/helpers'
import './App.css'

const App = ({ history, location }) => {
    const [checkingSession, setCheckingSession] = useState(true)
    const [user, setUser] = useState(null)
    const [isDecibio, setIsDecibio] = useState(false)
    const [isSuperUser, setIsSuperUser] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isMember, setIsMember] = useState(false)
    const [isLowCredits, setIsLowCredits] = useState(false)
    const [currentTeam, setCurrentTeam] = useState(null)

    const [loadMe, { loading, data }] = useLazyQuery(LOAD_ME_QUERY, {
        onCompleted: (data) => {
            if (
                data.me.membership.length === 0 ||
                !data.me.membership.some(
                    (obj) =>
                        obj.teamName === 'Decibio' || obj.teamName === 'Dexter'
                )
            ) {
                history.push('/first-time-signup-notice')
            } else {
                setUser(data.me)
                setIsDecibio(
                    data.me.membership.some((obj) => obj.teamName === 'Decibio')
                )
                setIsSuperUser(isSuperAdmin(data.me.membership))
                setIsAdmin(
                    isTeamAdmin(
                        data.me.membership[0].teamId,
                        data.me.membership
                    )
                )
                setIsMember(
                    isTeamMember(
                        data.me.membership[0].teamId,
                        data.me.membership
                    )
                )
                setCurrentTeam(data.me.membership[0].teamId)
            }
        },
    })

    useEffect(() => {
        if (data) {
            setUser(data.me)
        }

        if (currentTeam && user) {
            setIsAdmin(isTeamAdmin(currentTeam, user.membership))
            setIsMember(isTeamMember(currentTeam, user.membership))
        }

        const checkAuth = async () => {
            if (location.pathname === '/callback') {
                setCheckingSession(false)
                return
            }
            try {
                await auth0Client.silentAuth()
                if (!user) loadMe()
            } catch (err) {
                console.log(err)
                if (err.error !== 'login_required') console.log(err.error)
            }
            setCheckingSession(false)
        }

        checkAuth()
        if (window.location.origin !== process.env.REACT_APP_BASE_URL) {
            window.location.href = `${process.env.REACT_APP_BASE_URL}${window.location.pathname}`
        }
    }, [loading, loadMe, data, user, currentTeam, location.pathname])

    const path = location.pathname.substring(1).split('/')

    const isSignupPage =
        path.length === 1 && (path[0] === 'login' || path[0] === '/signup')

    const isExtractionReview =
        path[0] === 'admin' && path[1] === 'experts' && path[3] === 'review'

    return (
        <AppContext.Provider
            value={{
                user,
                setUser,
                isDecibio,
                setIsDecibio,
                isSuperUser,
                isAdmin,
                isMember,
                setIsLowCredits,
                currentTeam,
                setCurrentTeam,
                onLoad: loading,
            }}
        >
            <div className="site-wrap">
                <AppHeader showLowCreditMessage={!loading && isLowCredits} />
                <div
                    className={
                        isSignupPage || isExtractionReview
                            ? 'fullscreen'
                            : 'main'
                    }
                >
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => <Redirect to="/projects" />}
                        />
                        <SecureRoute
                            exact
                            path="/payments"
                            component={Payment}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/projects"
                            component={Project}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/projects/new-project"
                            component={NewProject}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/projects/:type"
                            component={Project}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/projects/:id"
                            component={SingleProject}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/projects/:id/:page"
                            component={SingleProject}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/tutorial"
                            component={Tutorial}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/admin"
                            noAccess={!isSuperUser}
                            component={Admin}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/admin/:page"
                            noAccess={!isSuperUser}
                            component={Admin}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/admin/experts/:id/review"
                            noAccess={!isSuperUser}
                            component={ExtractionReview}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/admin/:page/:id/:type"
                            noAccess={!isSuperUser}
                            component={Admin}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/advisors"
                            component={Advisors}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/faq"
                            component={FAQ}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/first-time-signup-notice"
                            component={FirstTimeSignupPage}
                            checkingSession={checkingSession}
                        />
                        <SecureRoute
                            exact
                            path="/jobs/:id"
                            component={Job}
                            checkingSession={checkingSession}
                        />
                        <Route exact path="/login" component={SignIn} />
                        <Route exact path="/signup" component={SignIn} />
                        <Route exact path="/callback" component={Callback} />
                        <Route
                            exact
                            path="/not-found-page"
                            component={NoMatch}
                        />
                        <Route component={NoMatch} />
                    </Switch>
                </div>
            </div>
        </AppContext.Provider>
    )
}

export default withRouter(App)
