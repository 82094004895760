import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Sidebar } from 'semantic-ui-react'
import { LOAD_TEAMS_QUERY } from '../../../graphql/team'
import TeamTable from './TeamTable'
import TeamControlPanel from './TeamControlPanel'
import LoadingSpinner from '../../LoadingSpinner'
import { AppContext } from '../../../Context'

const NEW_TEAM = {
    company: '',
    name: '',
    phone: '',
    adminName: '',
    adminEmail: '',
}

const ManageTeam = () => {
    const { onLoad } = useContext(AppContext)

    const [teams, setTeams] = useState([])
    const [showPanel, setShowPanel] = useState(false)

    const { loading } = useQuery(LOAD_TEAMS_QUERY, {
        onCompleted: (data) => {
            setTeams(data.teams)
        },
    })

    if (loading || onLoad) return <LoadingSpinner />

    return (
        <Sidebar.Pushable style={{ width: '100%' }}>
            <Sidebar
                animation="overlay"
                onHide={() => setShowPanel(false)}
                direction="right"
                visible={showPanel}
                style={{ background: 'white', width: '25%', zIndex: 2999 }}
            >
                <TeamControlPanel
                    type={'CREATE_TEAM'}
                    team={NEW_TEAM}
                    teams={teams}
                    setTeams={setTeams}
                    onCancel={() => setShowPanel(false)}
                />
            </Sidebar>
            <Sidebar.Pusher>
                <Button
                    primary
                    floated="right"
                    style={{ margin: '1% 0 1% 0' }}
                    onClick={() => setShowPanel(true)}
                >
                    Create new team
                </Button>
                <TeamTable teams={teams} />
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    )
}

export default ManageTeam
