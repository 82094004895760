import { gql } from '@apollo/client'
import { SINGLE_PROJECT_MODEL } from './project'

const PAYMENT_MODEL = `
    method
    amount
    enteredBy
    description
    paymentId
    createdAt
`

export const JOB_MODEL = `
    id
    interviewer {
        id
    }
    user {
        id
        fullName
        firstName
        lastName
        email
        expert {
            id
            country
            stripe_account
        }
    }
    project {
        id
        code
        team {
            id
            name
        }
    }
    meetingAt
    zoomId
    completedAt
    invite_status
    response_status
    overall_status
    payment {
        ${PAYMENT_MODEL}
    }
    expert_rating
    expert_comment
    invitedAt
    invitedReminderAt
    createdAt
`

export const MANAGE_JOB_MODEL = `
    id
    interviewers {
        id
        email
        fullName
        firstName
        lastName
    }
    user {
        id
        fullName
        firstName
        lastName
        email
        timeZone
        expert {
            id
            status
            salutation
            country
            title
            organization {
                name
            }
            stripe_account
            payment_notify
            internal_tags
            hourlyRate
            functions
            medical_specialties
            technology_tags
        }
    }
    project {
        id
        title
        status
        description
        code
        type
        service_type
        qualtricsSurvey {
            surveyId
        }
        jobs {
            id
            user {
                id
            }
            email_logs {
                subject
                sent_at
            }
            invite_status
            response_status
        }
        owners {
            id
            email
        }
        team {
            id
            name
        }
    }
    email_logs {
        subject
        sent_at
    }
    invite_status
    response_status
    overall_status
    payment {
        ${PAYMENT_MODEL}
    }
    expert_comment
    expert_rating
    meetingAt
    completedAt
    zoomId
    ipAddress
    updatedAt
    offerRate
    qualtricsContact {
        responseId
    }
    meetingReport {
        status
        participants {
            name
            email
            joinedAt
            leftAt
            numMinutes
        }
    }
`

export const LOAD_MANAGED_JOB_QUERY = gql`
    query Job($id: String!) {
        job(id: $id) {
            ${MANAGE_JOB_MODEL}
        }
    }
`

export const LOAD_MANAGE_ADVISOR_CARD = gql`
    query Job($id: String!) {
        job(id: $id) {
            id
            invite_status
            response_status
            overall_status
            payment {
                ${PAYMENT_MODEL}
            }
            invitedAt
            invitedReminderAt
            email_logs {
                subject
                sent_at
            }
            offerRate
            expert_rating
            expert {
                id
                stripe_account
                reminder_notify
            }
        }
    }
`

export const JOB_BASIC = gql`
    query Job($id: String!) {
        job(id: $id) {
            id
            overall_status
            meetingAt
            user {
                id
                email
                fullName
            }
            expert {
                id
                country
                stripe_account
            }
            project {
                id
                type
                title
                team {
                    id
                    name
                }
                qualtricsSurvey {
                    surveyId
                }
            }
        }
    }
`

export const LOAD_JOBS_QUERY = gql`
    query Jobs($filters: JobFilters, $pagination: Pagination, $sortBy: JobSortBy) {
      jobs(filters: $filters, pagination: $pagination, sortBy: $sortBy) {
          ${JOB_MODEL}
      }
    }
`

export const LOAD_PAYMENT_JOBS_QUERY = gql`
    query Jobs(
        $filters: JobFilters
        $pagination: Pagination
        $sortBy: JobSortBy
    ) {
        jobs(filters: $filters, pagination: $pagination, sortBy: $sortBy) {
            id
            payment {
                ${PAYMENT_MODEL}
            }
            user {
                id
                fullName
                firstName
                lastName
                email
                expert {
                    id
                    country
                    stripe_account
                }
            }
            project {
                id
                code
                team {
                    id
                    name
                }
            }
        }
    }
`

export const LOAD_JOBS_TOTAL_COUNT = gql`
    query Jobs($filters: JobFilters, $pagination: Pagination) {
        jobs(filters: $filters, pagination: $pagination) {
            id
        }
    }
`

export const LOAD_USER_JOBS_QUERY = gql`
    query UserJobs($id: String!) {
        user(id: $id) {
            id
            jobs {
                id
                project {
                    id
                    title
                    type
                    status
                    description
                }
                completedAt
                invite_status
                response_status
                overall_status
                payment {
                    ${PAYMENT_MODEL}
                }
                expert_rating
                expert_comment
                createdAt
            }
        }
    }
`

export const UPDATE_JOB_MUTATION = gql`
    mutation UpdateJob($userId: ID!, $projectId: ID!, $job: JobInput!) {
        updateJob(userId: $userId, projectId: $projectId, job: $job) {
            id
            project {
                ${SINGLE_PROJECT_MODEL}
            }
            offerRate
            expert_rating
        }
    }
`

export const COMPLETE_JOB_MUTATION = gql`
    mutation CompleteJob($jobId: ID!) {
        completeJob(jobId: $jobId) {
            id
            project {
                id
                team {
                    id
                }
            }
            overall_status
        }
    }
`

export const DELETE_JOB_MUTATION = gql`
    mutation DeleteJob($id: ID!) {
        deleteJob(id: $id) {
            id
        }
    }
`
