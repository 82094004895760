import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Sidebar } from 'semantic-ui-react'
import ProjectControlPanel from './ProjectControlPanel'
import ProjectTable from './ProjectTable'
import LoadingSpinner from '../../LoadingSpinner'
import { LOAD_MANAGE_PROJECTS_QUERY } from '../../../graphql/admin'

const ManageProject = () => {
    const [showPanel, setShowPanel] = useState(false)
    const [selectedProject, setSelectedProject] = useState({})

    const { data, loading } = useQuery(LOAD_MANAGE_PROJECTS_QUERY)

    if (loading) return <LoadingSpinner />

    return (
        <Sidebar.Pushable style={{ width: '100%' }}>
            <Sidebar
                animation="overlay"
                onHide={() => setShowPanel(false)}
                direction="right"
                visible={showPanel}
                style={{ background: 'white', width: '40%', zIndex: 2999 }}
            >
                <ProjectControlPanel
                    projectId={selectedProject.id}
                    onCancel={() => setShowPanel(false)}
                />
            </Sidebar>
            <Sidebar.Pusher>
                <ProjectTable
                    projects={data.adminPortal.actionableProjects}
                    setSelectedProject={setSelectedProject}
                    setShowPanel={setShowPanel}
                />
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    )
}

export default ManageProject
