import React, { useState, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { Sidebar, Ref } from 'semantic-ui-react'
import JobControlPanel from './JobControlPanel'
import JobTable from './JobTable'
import LoadingSpinner from '../../LoadingSpinner'
import { LOAD_MANAGE_JOBS_QUERY } from '../../../graphql/admin'

const ManageJob = () => {
    const [showPanel, setShowPanel] = useState(false)
    const [selectedJob, setSelectedJob] = useState({})
    const outsideSidebarRef = useRef()

    const { data, loading } = useQuery(LOAD_MANAGE_JOBS_QUERY)

    if (loading) return <LoadingSpinner />

    return (
        <Sidebar.Pushable style={{ width: '100%' }}>
            <Sidebar
                animation="overlay"
                onHide={() => setShowPanel(false)}
                target={outsideSidebarRef}
                direction="right"
                visible={showPanel}
                style={{ background: 'white', width: '40%', zIndex: 2999 }}
            >
                <JobControlPanel
                    jobId={selectedJob.id}
                    onCancel={() => setShowPanel(false)}
                />
            </Sidebar>
            <Ref innerRef={outsideSidebarRef}>
                <JobTable
                    jobs={data.adminPortal.actionableJobs}
                    setSelectedJob={setSelectedJob}
                    setShowPanel={setShowPanel}
                />
            </Ref>
        </Sidebar.Pushable>
    )
}

export default ManageJob
