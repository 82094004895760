/* eslint-disable react/prop-types */
import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'

const PaymentConfirm = ({
    advisor,
    paymentInfo,
    creditInfo,
    onBack,
    onNext,
    loading,
}) => (
    <>
        <Modal.Body>
            <span>To be processed:</span>
            <br />
            <span>
                {`${
                    paymentInfo.paymentMethod === 'Stripe'
                        ? `Pay ${advisor.fullName || advisor.email} $${
                              paymentInfo.paymentAmount
                          }`
                        : `Record an external payment of $${
                              paymentInfo.paymentAmount
                          } to ${advisor.fullName || advisor.email}`
                } via ${paymentInfo.paymentMethod}`}
            </span>
            <br />
            {creditInfo && (
                <span>{`Debit ${creditInfo.credit} credit(s) from client's account`}</span>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button icon basic onClick={onBack}>
                <Icon name="angle left" />
            </Button>
            <Button loading={loading} disabled={loading} onClick={onNext}>
                Confirm
            </Button>
        </Modal.Footer>
    </>
)

export default PaymentConfirm
