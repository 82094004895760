/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
    Container,
    Header,
    Button,
    Card,
    List,
    Flag,
    Accordion,
    Icon,
    Popup,
} from 'semantic-ui-react'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import SocialIcon from '../../Resources/SocialIcon'
import countries from '../../../constants/countries.json'
import StatusProgression from '../../Advisors/AdvisorCard/ManageAdvisorCard/StatusProgression'
import CompleteButton from '../../Advisors/AdvisorCard/ManageAdvisorCard/CompleteButton'
import PayButton from '../../Advisors/AdvisorCard/ManageAdvisorCard/PayButtons'
import InviteReminderModal from '../../Modals/ProjectInvite/InviteReminderModal'
import LoadingSpinner from '../../LoadingSpinner'
import { MARK_NO_SHOW } from '../../../graphql/meeting'
import { LOAD_MANAGE_JOBS_QUERY } from '../../../graphql/admin'
import {
    LOAD_MANAGED_JOB_QUERY,
    UPDATE_JOB_MUTATION,
} from '../../../graphql/job'

const QUALTRICS_SURVEY_RESPONSE_URL = `https://decibio.qualtrics.com/login?path=%2FQ%2FData%2FAjax%2FGetSingleResponseReport%3F`

const JobControlPanel = ({ jobId, onCancel }) => {
    const [showReminderModal, setShowReminderModal] = useState(false)
    const [showLogs, setShowLogs] = useState(false)

    const [
        loadJob,
        {
            data: jobData,
            called: jobCalled,
            loading: jobLoading,
            error: jobError,
        },
    ] = useLazyQuery(LOAD_MANAGED_JOB_QUERY)

    const [markNoShow, { loading }] = useMutation(MARK_NO_SHOW, {
        refetchQueries: [
            { query: LOAD_MANAGE_JOBS_QUERY },
            { query: LOAD_MANAGED_JOB_QUERY, variables: { id: jobId } },
        ],
    })

    const [expireJob, { loading: expiring }] = useMutation(
        UPDATE_JOB_MUTATION,
        {
            refetchQueries: [
                { query: LOAD_MANAGE_JOBS_QUERY },
                { query: LOAD_MANAGED_JOB_QUERY, variables: { id: jobId } },
            ],
            onCompleted: onCancel,
        }
    )

    const [cancelJob, { loading: cancelling }] = useMutation(
        UPDATE_JOB_MUTATION,
        {
            refetchQueries: [
                { query: LOAD_MANAGE_JOBS_QUERY },
                { query: LOAD_MANAGED_JOB_QUERY, variables: { id: jobId } },
            ],
            onCompleted: onCancel,
        }
    )

    useEffect(() => {
        if (jobId) {
            loadJob({
                variables: {
                    id: jobId,
                },
            })
        }
    }, [jobId, loadJob])

    const checkInfoEmpty = (info) =>
        !info || info === '(none)' || info === '(unknown)'

    if (!jobCalled || jobLoading) {
        return <LoadingSpinner />
    }

    if (jobError) {
        console.log(jobError)
        return <div></div>
    }

    const renderButton = () => {
        switch (jobData.job.overall_status) {
            case 'Pending Scheduling':
                return (
                    <Button
                        size="small"
                        floated="right"
                        onClick={() => {
                            setShowReminderModal(true)
                            onCancel()
                        }}
                    >
                        Send a reminder
                    </Button>
                )
            case 'Scheduled':
                if (
                    jobData.job.meetingReport &&
                    jobData.job.meetingReport.status !== 'Complete'
                ) {
                    return (
                        <Button
                            floated="right"
                            size="mini"
                            loading={expiring}
                            disabled={expiring}
                            onClick={() =>
                                expireJob({
                                    variables: {
                                        userId: jobData.job.user.id,
                                        projectId: jobData.job.project.id,
                                        job: {
                                            overall_status: 'Expired',
                                        },
                                    },
                                })
                            }
                        >
                            Mark Expired
                        </Button>
                    )
                }
                return (
                    <CompleteButton
                        advisor={jobData.job.user}
                        project={jobData.job.project}
                        job={jobData.job}
                    />
                )
            case 'Completed':
                return (
                    <PayButton
                        advisor={jobData.job.user}
                        project={jobData.job.project}
                        job={jobData.job}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Container className="side-panel">
            <InviteReminderModal
                onShow={showReminderModal}
                onHide={() => setShowReminderModal(false)}
                advisor={jobData.job.user}
                project={jobData.job.project}
                job={jobData.job}
                onSave={() =>
                    loadJob({
                        variables: {
                            id: jobId,
                        },
                    })
                }
            />
            <Header as="h1">{jobData.job.project.team.name}</Header>
            <Header as="h2">{jobData.job.project.title}</Header>
            <Header as="h3">
                Interviewers:{' '}
                {jobData.job.interviewers
                    ? jobData.job.interviewers
                          .map(
                              (interviewer) =>
                                  interviewer.fullName || interviewer.email
                          )
                          .join(', ')
                    : 'No interviewer'}
            </Header>
            <Card fluid>
                <Card.Content>
                    <SocialIcon advisor={jobData.job.user} />
                    <Card.Header className="expert_name_link">
                        {jobData.job.user.fullName || jobData.job.user.email}
                        {checkInfoEmpty(jobData.job.user.expert.country) ? (
                            ''
                        ) : (
                            <Popup
                                content={jobData.job.user.expert.country}
                                trigger={
                                    <Flag
                                        style={{ marginLeft: '5px' }}
                                        name={
                                            countries[
                                                jobData.job.user.expert.country
                                            ]
                                        }
                                    />
                                }
                            />
                        )}
                    </Card.Header>
                    <Card.Meta>
                        {checkInfoEmpty(jobData.job.user.expert.title)
                            ? ''
                            : jobData.job.user.expert.title}{' '}
                        {checkInfoEmpty(jobData.job.user.expert.title) ||
                        checkInfoEmpty(
                            jobData.job.user.expert.organization.name
                        )
                            ? ''
                            : ','}{' '}
                        {checkInfoEmpty(
                            jobData.job.user.expert.organization.name
                        )
                            ? ''
                            : jobData.job.user.expert.organization.name}
                    </Card.Meta>
                    <Card.Description className="card-description">
                        <StatusProgression
                            project={jobData.project}
                            job={jobData.job}
                        />
                    </Card.Description>
                </Card.Content>
            </Card>
            <div style={{ marginTop: '1rem' }}>
                {['Scheduled', 'No Show'].includes(
                    jobData.job.overall_status
                ) && (
                    <div className="ui two mini buttons">
                        <Button
                            loading={loading}
                            disabled={loading}
                            style={{ marginRight: '1rem' }}
                            onClick={() => {
                                markNoShow({
                                    variables: {
                                        jobId,
                                        absentee: 'Expert',
                                    },
                                })
                            }}
                        >
                            Mark Client{' '}
                            {jobData.job.interviewers[0].fullName ||
                                jobData.job.interviewers[0].email}{' '}
                            as present
                        </Button>
                        <Button
                            loading={loading}
                            disabled={loading}
                            onClick={() =>
                                markNoShow({
                                    variables: {
                                        jobId,
                                        absentee: 'Client',
                                    },
                                })
                            }
                        >
                            Mark Expert{' '}
                            {jobData.job.user.fullName ||
                                jobData.job.user.email}{' '}
                            as present
                        </Button>
                    </div>
                )}
            </div>
            <Accordion className="email-logs">
                <Accordion.Title
                    active={showLogs}
                    onClick={() => setShowLogs(!showLogs)}
                    className="email-logs-title"
                >
                    <Icon name="dropdown" />
                    Email logs
                </Accordion.Title>
                <Accordion.Content
                    active={showLogs}
                    className="email-logs-content"
                >
                    {jobData.job.email_logs ? (
                        <ul className="email-logs-list">
                            {jobData.job.email_logs.map((log, index) => {
                                return (
                                    <li key={index}>
                                        {`"${log.subject}" sent at ${moment(
                                            log.sent_at
                                        ).format('lll')}`}
                                    </li>
                                )
                            })}
                        </ul>
                    ) : (
                        '(none)'
                    )}
                </Accordion.Content>
            </Accordion>
            <div style={{ marginLeft: '1.5rem' }}>
                {jobData.job.project.type === 'Interview' ? (
                    <Header as="h5">Meeting logs</Header>
                ) : (
                    jobData.job.qualtricsContact?.responseId && (
                        <Button
                            basic
                            onClick={() =>
                                window.open(
                                    `${QUALTRICS_SURVEY_RESPONSE_URL}RID=${jobData.job.qualtricsContact.responseId}&SID=${jobData.job.project.qualtricsSurvey.surveyId}`,
                                    '_blank'
                                )
                            }
                        >
                            View survey response
                        </Button>
                    )
                )}
                <span className="email-logs-list">
                    {jobData.job.meetingAt
                        ? `Scheduled for ${momentTZ
                              .tz(
                                  jobData.job.meetingAt,
                                  jobData.job.user.timeZone
                              )
                              .format('lll z')}`
                        : null}
                </span>
                {jobData.job.meetingReport &&
                    jobData.job.meetingReport.status === 'Complete' && (
                        <Fragment>
                            <label style={{ fontSize: '13px', color: 'black' }}>
                                Meeting Participants
                            </label>
                            <List size="tiny" bulleted>
                                {jobData.job.meetingReport.participants.map(
                                    (participant, index) => {
                                        return (
                                            <List.Item key={index}>
                                                {`
                                                ${participant.name} ${
                                                    participant.email
                                                        ? `(${participant.email})`
                                                        : ''
                                                }: 
                                                ${moment(
                                                    participant.joinedAt
                                                ).format('LT')} - ${moment(
                                                    participant.leftAt
                                                ).format('LT')}
                                                (${
                                                    participant.numMinutes
                                                } minutes)
                                            `}
                                            </List.Item>
                                        )
                                    }
                                )}
                            </List>
                            <br />
                        </Fragment>
                    )}
                {jobData.job.ipAddress && <span>{jobData.job.ipAddress}</span>}
            </div>
            {renderButton()}
            <Button
                basic
                floated="right"
                size="mini"
                loading={cancelling}
                disabled={cancelling}
                onClick={() =>
                    cancelJob({
                        variables: {
                            userId: jobData.job.user.id,
                            projectId: jobData.job.project.id,
                            job: {
                                overall_status: 'Cancelled',
                            },
                        },
                    })
                }
            >
                Cancel job
            </Button>
        </Container>
    )
}

export default JobControlPanel
