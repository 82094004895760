/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Button, Form, Label, Input, Icon } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import { toCredit } from '../../../utils/helpers'

const DebitModal = ({ advisor, onBack, onNext }) => {
    const expertRate = toCredit(advisor.expert.hourlyRate)
    const [credit, setCredit] = useState(expertRate)
    return (
        <>
            <Modal.Body>
                <span>Debit Credit</span>
                <br />
                <span>{`Expert rate: ${expertRate} credit(s)`}</span>
                <Form.Field>
                    <label>
                        How many credits would you like to deduct from this
                        client’s account for this engagement?
                    </label>
                    <Input
                        labelPosition="right"
                        type="number"
                        min="0"
                        placeholder="Amount"
                        value={credit}
                        onChange={(e, { value }) =>
                            setCredit(parseFloat(value))
                        }
                    >
                        <input />
                        <Label>Credit(s)</Label>
                    </Input>
                </Form.Field>
            </Modal.Body>
            <Modal.Footer>
                <Button icon basic onClick={onBack}>
                    <Icon name="angle left" />
                </Button>
                <Button onClick={() => onNext({ credit })}>Review</Button>
            </Modal.Footer>
        </>
    )
}

export default DebitModal
