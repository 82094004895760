import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import Steps from './Steps'
import MainContainer from './MainContainer'

const ManageOrganization = () => {
    const [step, setStep] = useState(0)

    return (
        <Grid style={{ width: '100%' }}>
            <Grid.Column width={4}>
                <Steps step={step} />
            </Grid.Column>

            <Grid.Column width={12}>
                <MainContainer step={step} setStep={setStep} />
            </Grid.Column>
        </Grid>
    )
}

export default ManageOrganization
