import { gql } from '@apollo/client'
import { JOB_MODEL } from './job'

export const LOAD_UPCOMING_MEETINGS_QUERY = gql`
    query UpcomingMeetings {
        me {
            id
            upcomingMeetings {
                id
                interviewer {
                    id
                }
                user {
                    id
                    fullName
                    firstName
                    lastName
                    email
                }
                project {
                    id
                    title
                }
                meetingAt
                invite_status
                response_status
                overall_status
                expert_rating
            }
        }
    }
`
export const LOAD_PAST_MEETINGS_QUERY = gql`
    query PastMeetings {
        me {
            id
            pastMeetings {
                id
                interviewer {
                    id
                }
                user {
                    id
                    email
                    fullName
                    firstName
                    lastName
                }
                project {
                    id
                    title
                }
                meetingAt
                invite_status
                response_status
                overall_status
                expert_rating
            }
        }
    }
`

export const LOAD_MEETING_INFO_QUERY = gql`
    query Job($id: String!) {
        job(id: $id) {
            id
            meetingInvite
            meetingReport {
                status
                participants {
                    name
                    email
                    joinedAt
                    leftAt
                    numMinutes
                }
            }
        }
    }
`

export const UPDATE_CALENDAR_ENTRIES = gql`
    mutation UpdateCalendarEntries($startTimes: [Date], $available: Boolean) {
        updateCalendarEntries(startTimes: $startTimes, available: $available)
    }
`

export const SCHEDULE_MEETING = gql`
    mutation ScheduleMeeting($jobId: ID!, $startTime: Date) {
        scheduleMeeting(jobId: $jobId, startTime: $startTime) {
            ${JOB_MODEL}
        }
    }
`

export const RESCHEDULE_MEETING = gql`
    mutation RescheduleMeeting($jobId: ID!, $reason: String!) {
        rescheduleMeeting(jobId: $jobId, reason: $reason) {
            ${JOB_MODEL}
        }
    }
`

export const CANCEL_JOB = gql`
    mutation CancelJob($jobId: ID!, $reason: String!) {
        cancelJob(jobId: $jobId, reason: $reason) {
            ${JOB_MODEL}
        }
    }
`

export const MARK_NO_SHOW = gql`
    mutation MarkNoShow($jobId: ID!, $absentee: String!) {
        markNoShow(jobId: $jobId, absentee: $absentee) {
            ${JOB_MODEL}
        }
    }
`
