/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Modal } from 'react-bootstrap'
import Alert from '../../Alert'
import ProcessSuccess from './ProcessSuccess'
import PaymentConfirm from './PaymentConfirm'
import DebitModal from './DebitModal'
import PaymentInfo from './PaymentInfo'
import { SingleProjectContext } from '../../../Context'
import { SEND_EMAIL_MUTATION } from '../../../graphql/email'
import { PAY_EXPERT } from '../../../graphql/transaction'
import { LOAD_MANAGE_JOBS_QUERY } from '../../../graphql/admin'
import {
    UPDATE_JOB_MUTATION,
    LOAD_MANAGE_ADVISOR_CARD,
} from '../../../graphql/job'
import auth0Client from '../../Auth/Auth'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const PaymentModal = ({ onShow, onHide, advisor, job }) => {
    if (!job) {
        return <div></div>
    }
    const singleProjectContext = useContext(SingleProjectContext)
    const project = singleProjectContext
        ? singleProjectContext.project
        : job.project
    const isClient = project.service_type === 'Self-Service'

    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')
    const [paymentInfo, setPaymentInfo] = useState(null)
    const [creditInfo, setCreditInfo] = useState(null)
    const [modalPage, setModalPage] = useState('payment')

    const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)
    const [payAdvisor, { loading: paying }] = useMutation(PAY_EXPERT, {
        refetchQueries: [
            {
                query: LOAD_MANAGE_ADVISOR_CARD,
                variables: { id: job.id },
            },
        ],
        onCompleted: () => {
            const newEmailLogs = [
                {
                    subject: 'Dexter: You just got paid',
                    sent_at: new Date(),
                },
            ]

            if (paymentInfo.hasMessage) {
                newEmailLogs.push({
                    subject: `Re: Your Dexter interview on ${project.title}`,
                    sent_at: new Date(),
                })
            }
            setModalPage('success')
            updateJob({
                variables: {
                    userId: advisor.id,
                    projectId: project.id,
                    job: {
                        email_logs: job.email_logs.concat(newEmailLogs),
                    },
                },
                update: (cache, { data: { updateJob } }) => {
                    if (paymentInfo.hasMessage) {
                        const emailInfo = {
                            recipient: {
                                email: advisor.email,
                            },
                            sender: {
                                email: `${auth0Client.getProfile().email}`,
                                name: `${
                                    auth0Client.getProfile().name.includes('@')
                                        ? 'Dexter'
                                        : auth0Client.getProfile().name
                                }`,
                            },
                            topic: `Re: Your Dexter interview on ${project.title}`,
                            text: 'After payment email',
                            html: paymentInfo.message,
                        }
                        onSendEmail(
                            emailInfo.recipient,
                            emailInfo.sender,
                            emailInfo.topic,
                            emailInfo.text,
                            emailInfo.html
                        )
                    }
                    onHide()
                    setModalPage('payment')
                    setPaymentInfo(null)
                    setCreditInfo(null)
                },
            })
        },
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Unsuccessful payment')
            setAlertBody(`
                Failed to process the payment due to the following reason: ${error}. 
                Please try again or contact your site administrator if the problem persists
            `)
        },
    })
    const [updateJob, { loading: updating }] = useMutation(UPDATE_JOB_MUTATION)

    const onSendEmail = (recipient, sender, subject, text, html) => {
        const emailInput = {
            to: recipient,
            from: sender,
            subject,
            text,
            html,
        }
        sendEmail({
            variables: {
                input: emailInput,
            },
        })
    }

    const checkPayment = (info) => {
        const { paymentMethod } = info
        if (!paymentMethod) {
            setShowAlert(true)
            setAlertHeader('Missing require field')
            setAlertBody('Please select payment method')
            return
        }
        setPaymentInfo(info)
        if (isClient) setModalPage('debit')
        else setModalPage('confirm')
    }

    const onPay = () => {
        const { paymentAmount, paymentMethod, paymentDescription } = paymentInfo
        const payment = {
            type: 'Job Payment',
            amount: paymentAmount,
            method: paymentMethod,
            description: paymentDescription,
        }
        if (creditInfo?.credit) payment.credits = creditInfo.credit
        payAdvisor({
            variables: {
                jobId: job.id,
                payment,
            },
            update: (cache, { data: { payExpert } }) => {
                if (isClient) {
                    const managedJobsQuery = cache.readQuery({
                        query: LOAD_MANAGE_JOBS_QUERY,
                    })
                    const newAdminPortal = { ...managedJobsQuery.adminPortal }
                    const newActionableJobs =
                        newAdminPortal.actionableJobs.filter(
                            (job) => job.id !== payExpert.id
                        )
                    cache.writeQuery({
                        query: LOAD_MANAGE_JOBS_QUERY,
                        data: {
                            adminPortal: {
                                ...newAdminPortal,
                                actionableJobs: newActionableJobs,
                            },
                        },
                    })
                }
            },
        })
    }

    const renderModalPage = () => {
        switch (modalPage) {
            case 'debit':
                return (
                    <DebitModal
                        advisor={advisor}
                        onBack={() => setModalPage('payment')}
                        onNext={(info) => {
                            setCreditInfo(info)
                            setModalPage('confirm')
                        }}
                    />
                )
            case 'confirm':
                return (
                    <PaymentConfirm
                        advisor={advisor}
                        paymentInfo={paymentInfo}
                        creditInfo={creditInfo}
                        onBack={() =>
                            setModalPage(isClient ? 'debit' : 'payment')
                        }
                        onNext={onPay}
                        loading={paying || updating}
                    />
                )
            case 'success':
                return <ProcessSuccess />
            default:
                return (
                    <PaymentInfo
                        advisor={advisor}
                        project={project}
                        job={job}
                        onBack={onHide}
                        onNext={checkPayment}
                    />
                )
        }
    }

    return (
        <Modal show={onShow} onHide={onHide} centered style={{ zIndex: 9999 }}>
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${advisor.fullName || advisor.email} | ${project.title}`}
                </Modal.Title>
            </Modal.Header>
            {renderModalPage()}
        </Modal>
    )
}

export default PaymentModal
