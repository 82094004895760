import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import '../styles.css'

const TeamTable = ({ teams }) => {
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Company Name</Table.HeaderCell>
                    <Table.HeaderCell>Team Name</Table.HeaderCell>
                    <Table.HeaderCell>Credit Balance</Table.HeaderCell>
                    <Table.HeaderCell>Pending Expense</Table.HeaderCell>
                    <Table.HeaderCell>Available Credits</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {teams.map((team) => {
                    return (
                        <Table.Row key={team.id}>
                            <Table.Cell>{team.company}</Table.Cell>
                            <Table.Cell>
                                <Link
                                    to={`/admin/teams/${team.id}/credits`}
                                    className="link"
                                >
                                    {team.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>{team.creditBalance}</Table.Cell>
                            <Table.Cell>{team.pendingExpense}</Table.Cell>
                            <Table.Cell>
                                {team.creditBalance - team.pendingExpense}
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}

export default TeamTable
