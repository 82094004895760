import { gql } from '@apollo/client'

const TEAM_MODEL = `
    id
    status
    name
    company
    phone
    creditBalance
`

export const LOAD_TEAM_MEMBERS_QUERY = gql`
    query Team($id: ID!) {
        team(id: $id) {
            id
            members {
                userId
                user {
                    id
                    fullName
                    firstName
                    lastName
                    email
                }
                role
            }
        }
    }
`

export const LOAD_TEAMS_QUERY = gql`
    query Teams {
        teams {
            ${TEAM_MODEL}
            pendingExpense
        }
    }
`

export const LOAD_TEAMS_NAME_QUERY = gql`
    query Teams($ids: [ID!]) {
        teams(ids: $ids) {
            id
            name
        }
    }
`

export const LOAD_TEAM_NAME_QUERY = gql`
    query Team($id: ID!) {
        team(id: $id) {
            id
            name
        }
    }
`

export const LOAD_TEAM_INVITES_QUERY = gql`
    query Team($id: ID!) {
        team(id: $id) {
            id
            creditBalance
        }
    }
`

export const LOAD_SINGLE_TEAM_QUERY = gql`
    query Team($id: ID!) {
        team(id: $id) {
            ${TEAM_MODEL}
            numJobsCompleted
            members {
                user {
                    email
                }
            }
        }
    }
`

export const CREATE_TEAM_MUTATION = gql`
    mutation CreateTeam($team: TeamInput!, $adminName: String!, $adminEmail: String!) {
        createTeam(team: $team, adminName: $adminName, adminEmail: $adminEmail) {
            ${TEAM_MODEL}
        }
    }
`

export const UPDATE_TEAM_MUTATION = gql`
    mutation UpdateTeam($teamId: ID!, $team: TeamInput!) {
        updateTeam(teamId: $teamId, team: $team) {
            ${TEAM_MODEL}
        }
    }
`

export const ADD_MEMBER_MUTATION = gql`
    mutation AddMember($teamId: ID!, $email: String!, $role: String) {
        addMember(teamId: $teamId, email: $email, role: $role) {
            ${TEAM_MODEL}
        }
    }
`

export const UPDATE_MEMBER_MUTATION = gql`
    mutation UpdateMember($teamId: ID!, $userId: ID!, $role: String) {
        updateMember(teamId: $teamId, userId: $userId, role: $role) {
            ${TEAM_MODEL}
        }
    }
`

export const DELETE_MEMBER_MUTATION = gql`
    mutation DeleteMember($teamId: ID!, $userId: ID!) {
        deleteMember(teamId: $teamId, userId: $userId) {
            ${TEAM_MODEL}
        }
    }
`
