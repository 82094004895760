import { gql } from '@apollo/client'

const TRANSACTION_MODEL = `
    id
    type
    amount
    credits
    paymentId
    description
    createdAt
`

export const LOAD_TRANSACTIONS_QUERY = gql`
    query Transactions($teamId: ID, $pagination: Pagination) {
        transactions(teamId: $teamId, pagination: $pagination) {
            total
            transactions {
                ${TRANSACTION_MODEL}
                team {
                    id
                    name
                    creditBalance
                }
                job {
                    id
                    expert {
                        fullName
                        stripe_account
                        country
                    }
                    project {
                        code
                    }
                }
            }
        }
    }
`

export const LOAD_TRANSACTIONSBYDATE_QUERY = gql`
    query TransactionsByDate($teamId: ID, $startDate: Date!, $endDate: Date!) {
        transactionsByDate(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
            ${TRANSACTION_MODEL}
            method
            enteredBy
            job {
                id
                user {
                    id
                    email
                    fullName
                }
                project {
                    id
                    title
                    type
                    service_type
                    code
                }
            }
            team {
                id
            }
        }
    }
`

export const PAY_EXPERT = gql`
    mutation PayExpert($jobId: ID!, $payment: PaymentInput!) {
        payExpert(jobId: $jobId, payment: $payment) {
            id
        }
    }
`

export const ADD_TEAM_CREDIT_MUTATION = gql`
    mutation AddTeamCredit($teamId: ID!, $amount: Float!, $credits: Float!, $description: String!) {
        addTeamCredit(teamId: $teamId, amount: $amount, credits: $credits, description: $description) {
            ${TRANSACTION_MODEL}
            team {
                id
                creditBalance
            }
        }
    }
`

export const TRANSFER_CREDIT = gql`
    mutation TransferTeamCredit(
        $fromTeamId: ID!
        $toTeamId: ID!
        $credits: Float!
        $description: String
    ) {
        transferTeamCredit(
            fromTeamId: $fromTeamId
            toTeamId: $toTeamId
            credits: $credits
            description: $description
        ) {
            id
            team {
                id
                creditBalance
            }
        }
    }
`
