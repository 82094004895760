import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Container, Segment, List } from 'semantic-ui-react'
import moment from 'moment'
import { JOB_BASIC } from '../graphql/job'
import LoadingSpinner from '../components/LoadingSpinner'
import { QUALTRICS_SURVEY_URL } from '../components/Admin/Project/ProjectControlPanel'

const Job = () => {
    const { id } = useParams()
    const { data, loading } = useQuery(JOB_BASIC, {
        variables: { id },
    })
    if (loading) return <LoadingSpinner />

    const { job } = data

    const INFORMATION = [
        {
            name: 'Job status',
            value: job.overall_status,
        },
        {
            name: 'Team',
            value: job.project.team.name,
        },
        {
            name: 'Project title',
            value: job.project.title,
        },
        {
            name: 'Project type',
            value: job.project.type,
        },
        {
            name: 'Meeting time',
            value: job.meetingAt ? moment(job.meetingAt).format('LLL') : null,
        },
        {
            name: 'Qualtrics survey link',
            value: job.project.qualtricsSurvey
                ? `${QUALTRICS_SURVEY_URL}/${job.project.qualtricsSurvey.surveyId}/edit`
                : null,
        },
        {
            name: 'Expert name',
            value: `${job.user.fullName} (${job.user.email})`,
        },
        {
            name: 'Expert country',
            value: job.expert.country,
        },
        {
            name: 'Expert stripe status',
            value: job.expert.stripe_account ? 'Connected' : 'Not connected',
        },
    ]

    return (
        <Container>
            <Segment>
                <List>
                    {INFORMATION.filter((info) => info.value !== null).map(
                        (info) => (
                            <List.Item key={info.name}>
                                <List.Content>
                                    <List.Header>{info.name}</List.Header>
                                    <List.Description>
                                        {info.value}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    )}
                </List>
            </Segment>
        </Container>
    )
}

export default Job
